<!-- 进销存动表 -->
<template>
  <div class="pagePadding">
    <span class="pageBtn finger btnReset" @click="back">返回</span>
    <span class="pageBtn finger btnSure marginLeft20">
      <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Export">导出</span>
      <span v-if="Loagin" class="pageBtn finger btnSure">
        <Spin v-if="Loagin" style="display: inline-block; color: white;">
          <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
        </Spin>
      </span>
    </span>
    <Form inline class="formMarginBtm20" style="margin-top: 20px;">
      <div>
        <span>查询日期：</span>
        <span>{{ topData.search_date }}</span>
      </div>
      <div>
        <span>产品名称:</span>
        <span>{{ topData.product_name }}</span>
      </div>
      <div>
        <span>规格型号：</span>
        <span>{{ topData.model_name }}</span>
      </div>
      <div>
        <span>当前余量：</span>
        <span>{{ topData.remaining_quantity }}</span>
      </div>
      <div>
        <span>仓库：</span>
        <span>{{ topData.warehouse }}</span>
      </div>
      <div>
        <span>生产厂家：</span>
        <span>{{ topData.factory }}</span>
      </div>
      <div>
        <span>供应商：</span>
        <span>{{ topData.supplier }}</span>
      </div>
    </Form>
    <div class="clearfix tabDiv">
      <Table :productList="listColumns" :loading="Loading" :isLoad="Loading" :productData="listData" highlight-row border :pages="queryFrom" :total="total" totalText="条记录" @change-page="changePage" :TotalQuantity="TotalQuantity"></Table>
    </div>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'purchaseAndSaleFirstDetail',
  components: {
    Table,
  },
  data() {
    return {
      Loagin: false,
      Loading: true,
      topData: {},
      total: 0, // 项目条数
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 60,
        },
        {
          title: '日期',
          key: 'createTime',
          align: 'center',
          width: 100,
        },
        {
          title: '单据编号',
          key: 'order_number',
          align: 'center',
          width: 130,
        },
        {
          title: '单据类型',
          key: 'receipt_type',
          align: 'center',
          width: 100,
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          width: 130,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          width: 200,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 80,
        },
        {
          title: '入库数量',
          key: 'storage_in_quantity',
          align: 'center',
          width: 80,
        },
        {
          title: '出库数量',
          key: 'storage_out_quantity',
          align: 'center',
          width: 80,
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          width: 250,
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
          minWidth: 200,
        },
        {
          title: '客户名称',
          key: 'customer_name',
          align: 'center',
          width: 170,
        },
        {
          title: '操作',
          key: 'e',
          align: 'center',
          width: 75,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.goDetails(param.row)
                      // this.$router.push('/purchaseAndSaleSecendDetail')
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      queryFrom: {},
      TotalQuantity: [],
    }
  },
  created() {
    this.queryFrom = JSON.parse(this.$route.query.queryFrom)
    this.queryFrom.page = 1
    this.queryList()
  },
  methods: {
    // 导出
    Export() {
      if (this.Loagin) {
        return
      }
      let data = {
        begin_date: this.queryFrom.begin_date,
        end_date: this.queryFrom.end_date,
        code_id: this.queryFrom.code_id,
      }
      this.Loagin = true
      this.$http.downFile(this.$api.getstatementstock_sell_storagereceiptsexport, data, false).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '进销存表.xlsx'
        aLink.click()
        this.Loagin = false
      })
    },
    back() {
      this.$router.go(-1)
    },
    goDetails(item) {
      this.$router.push({
        path: '/purchaseAndSaleSecendDetail',
        query: {
          id: item.id,
          type: item.receipt_type == '采购入库单' || item.receipt_type == '销售退货单' ? 1 : 2,
          typeStr: item.receipt_type,
        },
      })
    },
    // 分页改变
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    queryList() {
      this.Loading = true
      this.$http.get(this.$api.statementStockSellStorageReceipts, this.queryFrom, true).then(res => {
        this.topData = res.title
        this.listData = res.data
        this.total = res.total
        // 出库单合计
        this.TotalQuantity.push({ title: '出库合计', total: res.out_total })
        this.TotalQuantity.push({ title: '入库合计', total: res.in_total })

        for (let i = 0; i < this.listData.length; i++) {
          this.listData[i].createTime = this.$moment(this.listData[i].create_time * 1000).format('YYYY-MM-DD')
        }
        this.Loading = false
      })
    },
  },
}
</script>

<style scoped lang="less">
.formMarginBtm20 {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>
